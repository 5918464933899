import React, { PureComponent } from 'react';
import { Author } from '../Author/Author';
import './NTitleItem.css';
import ReactPlayer from 'react-player';
import YoutubeImage from '../YoutubeImage/YoutubeImage';
import NSocialContainer from '../NSocialContainer/NSocialContainer';
import NGallery from '../NGallery/NGallery';
import { isMobile } from 'react-device-detect';

export default class NTitleItem extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isExpanded: props.expanded
        }
    }

    onDivClick = () => {
        this.setState({
            isExpanded: true
        })
    };

    render() {
        const { feedInfo } = this.props;
        const { isExpanded } = this.state;

        let feedTitle = feedInfo.title || '';
        let cutLength = 60;
        if (isMobile) { //Mobile
            if (isExpanded) {
                cutLength = 80;
            } else {
                cutLength = 60;
            }
        } else { //PC
            if (isExpanded) {
                cutLength = 150;
            } else {
                cutLength = 150;
            }
        }
        if (feedTitle.length > cutLength) {
            feedTitle = feedTitle.substr(0, cutLength) + '...'
        }

        if (!feedInfo) {
            return <span />
        }

        if (feedInfo.isAppPromo) {
            return <div className={`floating-box`}>
                <a href="https://play.google.com/store/apps/details?id=il.co.newser.app">
                    <div className="donwload-app-ad">
                        <div><img style={{ marginLeft: '5px' }} src="/favicons/newser32.png" alt="newser-icon" />{'להורדת ניוזר מחנות האפליקציות'}</div>
                    </div>
                </a>
            </div>
        }

        let titleUpperDiv = <span />
        if (feedInfo.videoType) {
            let videoURL = '';
            if (feedInfo.videoType === 'y') {
                videoURL = `https://www.youtube.com/watch?v=${feedInfo.videoId}`;
            } else if (feedInfo.videoType === 't') {
                videoURL = feedInfo.videoSrc;
            }
            titleUpperDiv = <div onClick={this.onDivClick}>

                <div className={`news-title-wrapper-div expanded`} ><Author feedInfo={feedInfo} />{feedTitle}  </div>
                <ReactPlayer style={{ display: 'flex' }} height="32vh" width="100%" controls url={videoURL} />

            </div>
        } else if (feedInfo.gallery && isExpanded) {
            titleUpperDiv = <div onClick={this.onDivClick}>
                <div>
                    <div className={`news-title-wrapper-div ${isExpanded && 'expanded'}`} ><Author feedInfo={feedInfo} />{feedTitle}  </div>
                    {
                        feedInfo.gallery && feedInfo.gallery.length > 1 ?
                            <NGallery gallery={feedInfo.gallery} />
                            :
                            <div style={{ backgroundImage: 'url(' + feedInfo.gallery[0] + ')' }} className="gallery-image" alt={feedInfo.provider}></div>
                    }

                </div>
            </div>
        }
        else {
            titleUpperDiv = <MinTitleDiv onClick={this.onDivClick} feedInfo={feedInfo} feedTitle={feedTitle} />
        }

        return <div className={`floating-box ${isExpanded ? 'expanded' : ''}`} onClick={isExpanded ? () => { } : this.onDivClick}>
            {titleUpperDiv}
            {
                isExpanded || feedInfo.videoType ?
                    <div className={`news-title-extended-div ${isExpanded ? 'expanded' : ''}`}>
                        <div className="title-description-div">
                            {feedInfo.description}
                        </div>
                        <NSocialContainer feedLink={feedInfo.link} feedTitle={feedTitle} feedDId={feedInfo.fId} />
                    </div> : <span />
            }
        </div>
    }
}


const MinTitleDiv = ({ onDivClick, feedInfo, feedTitle }) => {
    let moreDescription = '';
    if (feedTitle.length < 50 ) {
        moreDescription = feedInfo.description;
        if (moreDescription.length > 50) {
            moreDescription = moreDescription.substr(0,50) + '...';
        }
    }
    return <div className="news-title-min-div" onClick={onDivClick}>
        {
            feedInfo.videoId ?
                <div className="video-image-container">
                    <YoutubeImage height={'28vw'} image={feedInfo.image} videoLenght={feedInfo.length} views={feedInfo.views + ' צפיות'} />
                </div>
                :
                feedInfo.image && <div style={{ backgroundImage: 'url(' + feedInfo.image + ')' }} className="thumbnail-image" alt={feedInfo.provider}>
                    {
                        feedInfo.gallery && feedInfo.gallery.length > 1 && <div className="gallery-images-count">+{feedInfo.gallery.length - 1}</div>
                    }
                </div>
        }
        <div className={'news-title-wrapper-div'}><Author feedInfo={feedInfo} />
            {feedTitle}
        </div>
        {moreDescription}
    </div>
}