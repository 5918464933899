import React from 'react';
import './YoutubeImage.css';

export default class YoutubeImage extends React.Component {

    render() {
        const { width, height, videoLenght, image, views } = this.props;

        return (
            <div className="overlay">
                <div className="thumbnail" style={{ backgroundImage: 'url(' + image + ')' }} width={width} height={height} alt=""></div>
                <div className="video-lenght">{videoLenght}</div>
                <div className="video-views">{views}</div>
                <a href="#" className="playWrapper"></a>
            </div>
        );
    }
}
