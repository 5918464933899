import React from 'react';
import './MivzakimContainer.css';
import { Button, Input, ListGroupItem, ListGroup } from 'reactstrap';
import { NMTag } from '../NMTag/NMTag';
import Octicon, { LinkExternal } from '@primer/octicons-react'
import { Author } from '../Author/Author';
import { BrowserView, isMobile, isBrowser, MobileView } from "react-device-detect";

export class MivzakimContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: ''
        }
    }

    setSearchValue = (searchValue) => {
        this.setState({
            searchValue
        })
    };

    handleSearchChange = newLocation => {
        this.setSearchValue(newLocation);
    };

    render() {
        const { hashTags, newsFeed, onGoToLocationCLick, isSummary } = this.props;
        const { searchValue } = this.state;

        const onSearchInputChange = (event) => {
            this.setSearchValue(event.currentTarget.value);
        };

        return <div id="mivzakimContainer" name="mivzakimContainer">
            <div style={{ height: isMobile ? 'auto' : 'calc(100vh - 350px)', overflowX: 'hidden' }}>
                {
                    isSummary ? <div class="right-div-title"><div id="videosContainerTitle">{'10 מבזקים אחרונים'}</div></div> :
                        <div id="mivzakimSearchDiv">
                            {searchValue ? <Button style={{ width: '105px' }} onClick={() => {
                                this.setSearchValue('');
                            }} id="clearSearch">נקה חיפוש</Button> : <span />}
                            <Input value={searchValue} onChange={onSearchInputChange} id="rightSearch" placeholder="מבזקים" />
                        </div>
                }
                <div id="hashTagsContainer">
                    {
                        Object.keys(hashTags).filter(k => hashTags[k] > 1).map((hashKey, i) =>
                            <NMTag key={i} onClick={() => {
                                if (hashKey === searchValue) {
                                    this.setSearchValue('');
                                } else {
                                    this.setSearchValue(hashKey);
                                }
                            }} active={hashKey === searchValue} label={hashKey} count={hashTags[hashKey]} />
                        )
                    }
                </div>
                {
                    newsFeed ?
                        <ListGroup>
                            {
                                newsFeed.filter(newsItemToFilter => newsItemToFilter.title.indexOf(searchValue) > -1).slice(0, isSummary ? 10 : newsFeed.length).map((newsItem, i) =>
                                    <FeedItem onGoToLocationCLick={onGoToLocationCLick} key={i} feedInfo={newsItem} />
                                )
                            }
                        </ListGroup>
                        :
                        <span />
                }
            </div>
        </div>
    }
}

const FeedItem = ({ feedInfo, isRight, onGoToLocationCLick }) => {
    return <ListGroupItem onClick={() => {
        if (isMobile) {
            window.open(feedInfo.link);
        }
    }}>
        <div>
            <Author feedInfo={feedInfo} isRight={isRight} onGoToLocationCLick={onGoToLocationCLick} />
            <div>
                {/* {feedInfo.image ? <div style={{ backgroundImage: 'url(' + feedInfo.image + ')' }} className="news-image" alt={feedInfo.provider}></div> : <span />} */}
                {/* <a target="_blank" rel="noopener noreferrer" href={newsItem.link}>{newsItem.title}</a> */}
                <span>{`${feedInfo.title} `}</span>
                {
                    isBrowser && <a target="_blank" rel="noopener noreferrer" href={feedInfo.link}>
                        <Octicon icon={LinkExternal} />
                    </a>
                }
            </div>
        </div>
    </ListGroupItem>
}
