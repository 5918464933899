import React, { PureComponent } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

export default class NGallery extends PureComponent {
    render() {
        //   const images = [
        //     {
        //       original: 'https://picsum.photos/id/1018/1000/600/',
        //       thumbnail: 'https://picsum.photos/id/1018/250/150/',
        //     },
        //     {
        //       original: 'https://picsum.photos/id/1015/1000/600/',
        //       thumbnail: 'https://picsum.photos/id/1015/250/150/',
        //     },
        //     {
        //       original: 'https://picsum.photos/id/1019/1000/600/',
        //       thumbnail: 'https://picsum.photos/id/1019/250/150/',
        //     },
        //   ];

        return (
            <ImageGallery showPlayButton={false} showThumbnails={false} showFullscreenButton={false} items={this.props.gallery.map(s => {
                return {
                    original: s, thumbnail: s
                }
            })} />
        );
    }

}