import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* IONIC APP */
// import IonicApp from './Ionic/IonicApp';

/* SERVER SSR */
import App from './App';

/* Google Analytics */
import ReactGA from 'react-ga';
ReactGA.initialize('UA-149880899-1');
ReactGA.pageview(window.location.pathname + window.location.search);

/* SERVER SSR */
const renderMethod = !!module.hot ? ReactDOM.render : ReactDOM.hydrate
renderMethod(<App isWeb {...window.data} />, document.getElementById('root'));

/* IONIC APP */
// ReactDOM.render(<IonicApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
