export const AnchorNames = {
    walla: 'וואלה',
    ynet: 'Ynet',
    mako: 'מאקו',
    channel7: 'ערוץ 7',
    channel20: 'ערוץ 20',
    news0404: 'חדשות 0404',
    israelToday: 'ישראל היום',
    maariv: 'מעריב',
    kan: 'כאן',
    telegram: 'Telegram'
}

export const INITIAL_SOURCES = {
    ynet: true,
    walla: true,
    israelToday: false,
    mako: true,
    channel7: true,
    channel20: true,
    news0404: true,
    maariv: true,
    kan: true,
    telegram: true,
}

export const getBlockedSources = () => {
    const blocked = [];
    return Object.keys(INITIAL_SOURCES).forEach(k => {
        if (INITIAL_SOURCES[k]) {
            blocked.push(k);
        }
    })
}