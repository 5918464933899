import React from 'react';
import axios from 'axios';
import './App.css';
// import './Cyber.css';
import './ScrollBar.css';
import 'bootstrap-v4-rtl/dist/css/bootstrap-rtl.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
// import { MainMap } from './MainMap';

import {
  Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavLink,
  NavItem
} from 'reactstrap';
import {
  BrowserView,
  isBrowser,
  isMobile
} from "react-device-detect";
import { NMModalWindow } from './NMModalWindow/NMModalWindow';
import { MivzakimContainer } from './MivzakimContainer/MivzakimContainer';
import { Preloader } from './Preloader/Preloader';
import axiosRetry from 'axios-retry';
import InfList from './InfList/InfList';
import { getBlockedSources } from './Utils/Utils';
import { SourcesDiv } from './SourcesDiv/SourcesDiv';
import { Plugins } from '@capacitor/core';
const { SplashScreen } = Plugins;


// axiosRetry(axios, { retries: 2 });

const APP_MODES = {
  SUMMARY: 'SUMMARY',
  TITLES: 'TITLES',
  VIDEO: 'VIDEO',
  BREAKING: 'BREAKING'
}

let SERVICE_URL = 'https://www.newser.co.il/api';
// let SERVICE_URL = 'http://localhost:8080/api';
// let SERVICE_URL = 'http://192.168.1.15:8080/api';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.mivzakimContainer = React.createRef();

    if (SplashScreen) {
      SplashScreen.hide();
    }

    // this.onWindowScrollDebounce = _.debounce((e) => {
    //   this.onWindowScroll(e);
    // }, 5);

    // if (isMobile) {
    //   window.addEventListener('scroll', this.onWindowScrollDebounce);
    // }

    // if (process.env.NODE_ENV === "development") {
    //   SERVICE_URL = "http://localhost:8080/api"
    // };

    if (!props.newsFeed) {
      this.reloadDataFromServer();
    }

    this.state = {
      newsFeed: props.newsFeed || [],
      youtubeFeed: props.youtubeFeed || [],
      hashTags: props.hashTags || {},
      rightSearchValue: '',
      leftSearchValue: '',
      isCyberMode: false,
      showTraffic: false,
      isModalOpen: false,
      videoIdForModalWindow: '',
      titleFeed: props.titleFeed || [],
      scrollY: 0,
      activeTab: this.titlesContainer,
      selectedTab: APP_MODES.SUMMARY,
      isLoading: false,
      selectedTabToLoad: '',
      disabledSources: getBlockedSources()
    };
  }

  // onWindowScroll = () => {

  //   if (window.scrollY !== this.state.scrollY) {
  //     this.setState({
  //       scrollY: window.scrollY
  //     })
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (this.state.selectedTabToLoad) {
      this.setState({
        selectedTab: this.state.selectedTabToLoad,
        selectedTabToLoad: ''
      })
    }
  }

  // onAppScroll = (scrollTop) => {
  //   // console.log(scrollTop);
  // }

  fetchMoreListItems = (setIsFetching) => {
    const lastFeedIndex = this.state.titleFeed[this.state.titleFeed.length - 1].id;
    if (lastFeedIndex) {
      axios.get(SERVICE_URL + "/news/n?last=" + this.state.titleFeed[this.state.titleFeed.length - 1].id).then((response) => {
        if (response && response.data && Array.isArray(response.data)) {
          this.setState({
            titleFeed: [...this.state.titleFeed, ...response.data.reverse()]
          }, () => { setIsFetching(false) })
        } else {
          setIsFetching(false);
        }
      });
    } else {
      setIsFetching(false);
    }
  }

  reloadDataFromServer = () => {
    axios.get(SERVICE_URL + "/news/m").then((response) => {
      if (response && response.data && Array.isArray(response.data)) {
        this.setState({
          newsFeed: response.data
        })
      }
    });
    axios.get(SERVICE_URL + "/news/n").then((response) => {
      if (response && response.data && Array.isArray(response.data)) {
        this.setState({
          titleFeed: response.data.reverse()
        })
      }
    });
    axios.get(SERVICE_URL + "/news/y").then((response) => {
      this.setState({
        youtubeFeed: response.data.reverse()
      })
    });
    axios.get(SERVICE_URL + "/news/h").then((response) => {
      this.setState({
        hashTags: response.data
      })
    });
  };

  changeModalWindowVisibility = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  }

  onMapLocationClick = (locationName) => {
    this.mivzakimContainer.current.handleSearchChange(locationName);
  };

  onGoToLocationCLick = (location) => {
    this.mapRef.current.focusOnLocation(location)
  };

  openNavBar = () => {
    this.setState(prevState => ({
      isNavBarOpen: !prevState.isNavBarOpen
    }));
  }

  onVideoClick = (videoId) => {
    this.setState({
      isModalOpen: isBrowser,
      videoIdForModalWindow: videoId,
    });
  };

  onCloseVideoClick = () => {
    this.setState({
      videoIdForModalWindow: '',
    });
  };

  componentDidMount() {
    if (!!window.performance && window.performance.navigation.type === 2) {
      window.location.reload();
    } else {
      window.history.pushState(null, '', '/');
      this.setState({
        innerHeight: window.innerHeight
      })
    }
  }

  render() {
    const { newsFeed, rightSearchValue, youtubeFeed, isModalOpen,
      titleFeed, isCyberMode, showTraffic, hashTags, videoIdForModalWindow, selectedTab, scrollY, selectedTabToLoad } = this.state;
    const { isSSRMobile } = this.props;

    let appBody = <Preloader />
    if (isMobile || isSSRMobile) {
      if (selectedTab === APP_MODES.VIDEO && youtubeFeed && youtubeFeed.length) {
        appBody = <TitlesContainer fetchMoreListItems={this.fetchMoreListItems} numOfTitles={5} titleFeed={youtubeFeed} />
      } else if (selectedTab === APP_MODES.SUMMARY && titleFeed && titleFeed.length) {
        appBody = <div>
          <TitlesContainer isWeb={this.props.isWeb} fetchMoreListItems={this.fetchMoreListItems} numOfTitles={6} titleFeed={titleFeed} />
          {/* <MivzakimContainer isSummary onGoToLocationCLick={this.onGoToLocationCLick} ref={this.mivzakimContainer} rightSearchValue={rightSearchValue} hashTags={hashTags} newsFeed={newsFeed} />
          <NVideosContainer closeVideoClick={this.onCloseVideoClick} videoIdForModalWindow={videoIdForModalWindow} onVideoClick={this.onVideoClick} youtubeFeed={youtubeFeed} /> */}
        </div>
      } else if (selectedTab === APP_MODES.BREAKING && newsFeed && newsFeed.length) {
        appBody = <div>
          <MivzakimContainer onGoToLocationCLick={this.onGoToLocationCLick} ref={this.mivzakimContainer} rightSearchValue={rightSearchValue} hashTags={hashTags} newsFeed={newsFeed} />
        </div>
      } else if (selectedTab === APP_MODES.TITLES && titleFeed && titleFeed.length) {
        appBody = <div>
          <TitlesContainer numOfTitles={10} titleFeed={titleFeed} />
        </div>
      }
    }

    return (
      <div className={'App'}>
        <Navbar className="rtl nav-style" expand="md">
          <div id="blueNav">
            <div id="blueNavTriangle"></div>
          </div>
          <NavbarBrand href="/">
            <div className="logo-title">
              {'ניוזר - '}
            </div>
            <div className="logo-subtitle">
              {'(BETA) חדשות ומבזקי חדשות בזמן אמת'}
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={this.openNavBar} />
          <Collapse isOpen={this.state.isNavBarOpen} navbar>
            <Nav className="ml-auto" navbar>
              {/* <NavItem>
                <div style={{ margin: '8px' }}>
                  <div className="fb-like" data-href="https://www.facebook.com/&#x5e0;&#x5d9;&#x5d5;&#x5d6;&#x5e8;-&#x5db;&#x5d5;&#x5ea;&#x5e8;&#x5d5;&#x5ea;-&#x5d5;&#x5de;&#x5d1;&#x5d6;&#x5e7;&#x5d9;-&#x5d7;&#x5d3;&#x5e9;&#x5d5;&#x5ea;-&#x5d1;&#x5d6;&#x5de;&#x5df;-&#x5d0;&#x5de;&#x5ea;-106622177431390/" data-width="" data-layout="standard" data-action="like" data-size="small" data-show-faces="true" data-share="false"></div>
                </div>
              </NavItem>
              <NavItem>
                <CustomInput type="switch" value={showTraffic} onChange={(e) => { this.setState({ showTraffic: e.currentTarget.checked }) }} id="trafficSwitch" label="הצג תנועה" />
              </NavItem> */}
              {/* <NavItem>
                <CustomInput type="switch" value={isCyberMode} onChange={(e) => { this.setState({ isCyberMode: e.currentTarget.checked }) }} id="CyberSwitch" label="סייבר" />
              </NavItem> */}
              <NavItem>
                <NavLink href="mailto:yevgeni.kabishcher@gmail.com">צור קשר</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>

        <NMModalWindow isModalOpen={isModalOpen} toggle={this.changeModalWindowVisibility} videoIdForModalWindow={videoIdForModalWindow} />
        {
          isMobile || isSSRMobile ?
            <div id="mobileTopWrapper">
              {/* <IonicMenu /> */}
              <div className="mobile-tabs-container" style={{ top: scrollY > 35 ? 0 : 35 }}>
                <MobileMenuItem style={{ width: '33%' }} selectedTabToLoad={selectedTabToLoad} that={this} selectedTab={selectedTab} tabToSelect={APP_MODES.SUMMARY} label={'חדשות'} />
                {/* <MobileMenuItem selectedTabToLoad={selectedTabToLoad} that={this} selectedTab={selectedTab} tabToSelect={APP_MODES.TITLES} label={'כותרות'} /> */}
                <MobileMenuItem style={{ width: '33%' }} selectedTabToLoad={selectedTabToLoad} that={this} selectedTab={selectedTab} tabToSelect={APP_MODES.BREAKING} label={'מבזקים'} />
                <MobileMenuItem style={{ width: '33%' }} selectedTabToLoad={selectedTabToLoad} that={this} selectedTab={selectedTab} tabToSelect={APP_MODES.VIDEO} label={'וידאו'} />
              </div>
              <div id="mobileBodyContainer">
                {appBody}
              </div>
            </div>
            :
            <div id="browserTopWrapper">
              <div className="browser-view-wrapper">
                <div id="browserTitlesViewWrapper">
                  <TitlesContainer titleFeed={titleFeed} numOfTitles={4} fetchMoreListItems={this.fetchMoreListItems} />
                </div>
                <div id="browserMivzakimViewWrapper">
                  <div className="mivzakim-div-title"><h2>{'מבזקים בזמן אמת'}</h2></div>
                  <MivzakimContainer ref={this.mivzakimContainer} onGoToLocationCLick={this.onGoToLocationCLick} rightSearchValue={rightSearchValue} hashTags={hashTags} newsFeed={newsFeed} />
                  <div id="weatherWrapper">
                    <a className="weatherwidget-io" href="https://forecast7.com/he/32d0934d78/tel-aviv-yafo/" data-label_1="תל אביב" data-theme="original" data-basecolor="#ffffff" data-textcolor="#0a0a0a" data-highcolor="#000000" data-lowcolor="#a9b4c3" >תל אביב</a>
                  </div>
                </div>
                <div id="browserRightViewWrapper">
                <div class="fb-page" data-href="https://www.facebook.com/newser.co.il/" data-tabs="" data-width="" data-height="" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/newser.co.il/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/newser.co.il/">‎ניוזר - (BETA) ומבזקי חדשות בזמן אמת‎</a></blockquote></div>
                  <SourcesDiv />
                </div>
              </div>
            </div>
        }
      </div>
    );
  }
}

const TitlesContainer = ({ isWeb, titleFeed, numOfTitles, fetchMoreListItems }) => {
  if (isWeb && titleFeed && titleFeed.length) {
    //titleFeed = [titleFeed[0], { isAppPromo: true }, ...titleFeed.slice(1)]
  }

  return <div id="titlesContainer" name="titlesContainer">
    <BrowserView>
      <div className="right-div-title"><h2>{'חדשות - כותרות'}</h2></div>
    </BrowserView>

    {/* <div id="titlesDiv" > */}
    {titleFeed && <InfList fetchMoreListItems={fetchMoreListItems} listItems={titleFeed} />}
    {/* </div> */}
  </div>
}

const getYnetAndWalla = (titleFeed) => {
  let walla = null;
  let ynet = null;
  titleFeed.some(t => {
    if (!ynet && t.provider === 'ynet') {
      ynet = t;
    }
    if (!walla && t.provider === 'walla') {
      walla = t;
    }
    if (walla && ynet) return true;
  })
  return [ynet, walla]
}

const MobileMenuItem = ({ that, selectedTab, selectedTabToLoad, tabToSelect, label, style }) => {
  return <div onClick={() => {
    if (that.props.scrollToTop) {
      that.props.scrollToTop();
    }
    window.scrollTo(0, 0);
    that.setState({
      selectedTabToLoad: tabToSelect
    })
  }} style={style} className={`mobile-header-tab ${(selectedTab === tabToSelect || selectedTabToLoad === tabToSelect) && 'active'}`}>
    <span>{label}</span>
  </div>
}

