import React, { useState, useEffect } from 'react';
import NTitleItem from '../NTitleItem/NTitleItem';
import debounce from 'lodash/debounce';

const InfList = ({ listItems, fetchMoreListItems }) => {
    const [isFetching, setIsFetching] = useState(false);

    const onWindowScrollDebounce = debounce((e) => {
        handleScroll(e, false);
    }, 100);

    const onPCScrollDebounce = debounce((e) => {
        handleScroll(e.target, true);
    }, 100);

    useEffect(() => {
        window.addEventListener('scrollMobile', onWindowScrollDebounce);
        window.addEventListener('scroll', onWindowScrollDebounce);
        if (document.getElementById("browserTopWrapper")) {
            document.getElementById("browserTopWrapper").addEventListener('scroll', onPCScrollDebounce);
        }


        return () => {
            window.removeEventListener('scrollMobile', onWindowScrollDebounce)
            window.removeEventListener('scroll', onWindowScrollDebounce)
            if (document.getElementById("browserTopWrapper")) {
                document.getElementById("browserTopWrapper").removeEventListener('scroll', onPCScrollDebounce)
            }
        };
    }, []);

    useEffect(() => {
        if (!isFetching) return;
        fetchMoreListItems(setIsFetching);
    }, [isFetching]);

    const handleScroll = (e, pcBrowser) => {
        const scrollTop = e.scrollTop || window.scrollY;

        let scrollHeight = 0;
        if (pcBrowser) {
            scrollHeight = Math.max(e.scrollHeight, e.offsetHeight, e.clientHeight);
        } else {
            scrollHeight = Math.max(
                document.body.scrollHeight, document.documentElement.scrollHeight,
                document.body.offsetHeight, document.documentElement.offsetHeight,
                document.body.clientHeight, document.documentElement.clientHeight
            );
        }

        if (isFetching) return;
        // console.log('scrollTop', scrollTop);
        // console.log('window.innerHeight', window.innerHeight);
        // console.log('scrollHeight', scrollHeight);
        if (window) {
            if (window.innerHeight + scrollTop + 200 < scrollHeight) return;
        }

        setIsFetching(true);
    }

    return (
        <>
            <ul className="list-group mb-2">
                {listItems.map((listItem, i) => <NTitleItem expanded={i === 0} key={i} feedInfo={listItem} />)}
            </ul>
            {isFetching && 'טוען עוד כתבות...'}
        </>
    );
};

export default InfList;