import React from 'react';
import './Author.css';
import { isBrowser } from "react-device-detect";
import Octicon, { Location } from '@primer/octicons-react'
import { AnchorNames } from '../Utils/Utils';

export const Author = ({ hideTime, feedInfo, isRight, onGoToLocationCLick }) => {
    return <div>
        {/* {feedInfo.locations && isBrowser && !feedInfo.videoId && onGoToLocationCLick ? <div className="point-to-location-div" style={{ float: isRight ? 'right' : 'left' }}>
            <a onClick={() => { onGoToLocationCLick(feedInfo.locations[0]) }} href="#">
                <Octicon size={24} icon={Location} />
            </a>
        </div> : <span />} */}
        <div className={`img-div ${feedInfo.source || feedInfo.provider}`} style={{ float: !isRight ? 'right' : 'left' }}></div>
        <div className="anchor-div">
            <span>{`${AnchorNames[feedInfo.provider] || feedInfo.provider}`}</span>
            {!hideTime ? <span className="time">, {feedInfo.timeString}</span> : <span></span>}
        </div>
    </div>
}

export const AuthorBig = ({ feedInfo, isRight, onGoToLocationCLick }) => {
    return <div>
        {feedInfo.locations ? <div className="point-to-location-div" style={{ float: isRight ? 'right' : 'left' }}>
            <a onClick={() => { onGoToLocationCLick(feedInfo.locations[0]) }} href="#"><Octicon size={24} icon={Location} /></a>
        </div> : <span />}
        <div className={`img-div ${feedInfo.provider}`} style={{ float: !isRight ? 'right' : 'left' }}></div>
        <div className="anchor-div">
            <div className="time-div">{feedInfo.timeString}</div>
            <div>{AnchorNames[feedInfo.provider] || feedInfo.provider}</div>
        </div>
    </div>
}

export const TitleAuthor = ({ feedInfo, isRight, onGoToLocationCLick }) => {
    return <div className="title-author">
        <div className={`position-left img-div ${feedInfo.provider}`} style={{ float: !isRight ? 'right' : 'left' }}></div>
        <div>{AnchorNames[feedInfo.provider] || feedInfo.provider}</div>
    </div>
}