import React, { PureComponent } from 'react';
import './NSocialContainer.css';
export default class NSocialContainer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isExpanded: props.expanded
        }
    }

    onDivClick = () => {
        this.setState({
            isExpanded: !this.state.isExpanded
        })
    };

    render() {
        const { feedLink, feedTitle, feedDId } = this.props;
        const feedShareURL = 'https://www.newser.co.il?fid=' + feedDId;

        return <div className="news-title-share-div">
            
                <div className="news-title-external-link-div" onClick={() => {
                    window.open(feedLink);
                }}>
                    {feedLink && 'לכתבה המלאה...'}
                </div>
            

            <div className="share-div-icons-container">
                <div className="share-div-icon" onClick={() => {
                    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(feedShareURL),
                        'facebook-share-dialog', 'width=626,height=436');
                    return false;
                }}>
                    <div className="facebook-icon social-icon" />
                </div>
                <div className="share-div-icon" >
                
                    {/* <a href={`whatsapp://send?text=${feedTitle} - ${encodeURIComponent(feedShareURL)}`} >
                        <div className="whatsup-icon social-icon" />
                    </a> */}
                    <a target='_blank' rel="noopener noreferrer" href={`https://wa.me/?text=${encodeURIComponent(feedTitle + ' - ' + feedShareURL)}`} >
                        <div className="whatsup-icon social-icon" />
                    </a>
                </div>
                <div className="share-div-icon" >
                    <a href={`tg://msg?text=${feedTitle} - ${encodeURIComponent(feedShareURL)}`} >
                        <div className="telegram-icon social-icon" />
                    </a>
                </div>
            </div>
        </div>
    }
}


