import React, { useState } from 'react';
import './NMModalWindow.css';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import ReactPlayer from 'react-player';

export const NMModalWindow = ({isModalOpen, toggle, videoIdForModalWindow}) => {
    return <Modal isOpen={isModalOpen} toggle={toggle} className={'rtl'} >
    <ModalHeader toggle={toggle}>{'וידאו'}</ModalHeader>
    <ModalBody>
      <ReactPlayer width="100%" playing url={`https://www.youtube.com/watch?v=${videoIdForModalWindow}`} />
    </ModalBody>
  </Modal>
 
  }