import React, { useState } from 'react';
import { INITIAL_SOURCES, AnchorNames } from '../Utils/Utils';
import { CustomInput } from 'reactstrap';

export const SourcesDiv = () => {
    const [visibleSources, setVisibleSources] = useState(false);
    const sources = INITIAL_SOURCES;
    const sourcesKeyArray = Object.keys(sources);
    const onlyAvailable = Object.keys(sources).filter(k => INITIAL_SOURCES[k])
    return <div className="card-box">
        <div>{`${onlyAvailable.length} מתוך ${sourcesKeyArray.length} מקורות`}</div>
        {
            visibleSources ?
                <div>
                    {sourcesKeyArray.map((key, i) => {
                        return <div key={i}>
                            <CustomInput id={'source-' + key} onChange={e => {
                                console.log(e.target.checked);
                            }} checked={sources[key]} className="rtl" type="checkbox" label={AnchorNames[key]} />

                        </div>
                    })}
                </div> :
                <span />
        }
    </div>
}